import React, { CSSProperties, FunctionComponent } from 'react';

export const Logo: FunctionComponent<{
  className?: string;
  style?: CSSProperties;
}> = function Logo({ className, style }) {
  return (
    <svg
      className={className}
      style={{
        fontSize: '0.25em',
        width: '65.125em',
        height: '13.5em',
        ...style,
      }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1042 216"
    >
      <g>
        <path d="M00.883,200.901v-162.5h106.25v38.25h-68v86h74.25v38.25H16.883z" />
        <path d="M144.632,200.901v-125h137.5v125H160.632z M198.132,164.151h62.5v-51.5h-62.5V164.151z" />
        <path d="M319.631,200.901v-125h99.75v-56.25h37.75v181.25H335.631z M373.131,164.151h62.25v-51.5h-62.25V164.151z" />
        <path d="M494.63,200.901v-125h125v62.5h-37.5v-25.75h-50v50.75h75v37.5H510.63z" />
      </g>
      <g>
        <path
          d="M656.926,150.162l-0.244-14.282c-0.244-6.998,0.04-12.939,0.854-17.822c1.789-15.381,5.695-26.651,11.719-33.813
        c5.859-6.266,11.841-11.108,17.944-14.526c10.09-4.313,21.606-6.348,34.546-6.104c10.09,0,19.429,2.544,28.015,7.629
        c8.586,5.087,14.71,11.923,18.372,20.508c3.662,8.587,5.493,22.034,5.493,40.345v68.848h-17.09v-63.843
        c0-15.461-0.652-25.797-1.953-31.006c-2.035-8.869-6.063-15.156-12.085-18.859c-6.023-3.703-14.935-5.555-26.733-5.555
        c-10.418,0-19.695,3.012-27.832,9.033c-8.139,6.023-11.923,14.242-11.353,24.658c-1.547,6.674-2.319,18.841-2.319,36.499v49.072
        h-17.334V150.162z"
        />
        <path
          d="M808.049,63.858h17.334v62.5c0,15.301,0.813,25.799,2.441,31.494c2.441,8.221,7.12,14.709,14.038,19.471
        c6.916,4.76,15.217,7.141,24.902,7.141c9.602,0,17.74-2.319,24.414-6.958c6.672-4.639,11.271-10.742,13.794-18.311
        c1.789-5.127,2.686-16.071,2.686-32.837v-62.5h17.578v65.796c0,18.475-2.178,32.369-6.531,41.687
        c-4.354,9.319-10.845,16.602-19.47,21.852c-8.627,5.248-19.451,7.873-32.471,7.873c-13.104,0-24.008-2.625-32.715-7.873
        c-8.709-5.25-15.219-12.614-19.531-22.096c-4.314-9.479-6.47-23.742-6.47-42.785V63.858z"
        />
        <path d="M960.637,15.274h17.334v185.669h-17.334V15.274z" />
        <path d="M1010.93,15.274h17.334v185.669h-17.334V15.274z" />
      </g>
    </svg>
  );
};
