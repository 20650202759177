import React, { ComponentType, FunctionComponent } from 'react';
import { ProviderNavigation } from './provider/ProviderNavigation';
import { Redirect } from './Redirect';
import { Route } from './Route';

type Route =
  | {
      type: 'redirect';
      name: string;
      path: string;
      isExternal?: boolean;
      destination: string;
    }
  | {
      type: 'page';
      name: string;
      path: string;
      destination: ComponentType;
    };

export type ConfigurationRouter = {
  routes: Route[];
};

/*
 * Page routing.
 */
export const Router: FunctionComponent<{
  configuration: ConfigurationRouter;
}> = ({ configuration }) => {
  return (
    <ProviderNavigation>
      {configuration.routes.map((route, i) => {
        if (route.type === 'page') {
          return (
            <Route key={i} path={route.path}>
              <route.destination />
            </Route>
          );
        } else if (route.type === 'redirect') {
          return (
            <Route key={i} path={route.path}>
              <Redirect isExternal={route.isExternal} to={route.destination} />
            </Route>
          );
        } else {
          throw new Error(`Route type is invalid.`);
        }
      })}
    </ProviderNavigation>
  );
};
