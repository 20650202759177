import { createLogger } from '@null-studios/universal-logger';
import React, { FunctionComponent, ReactNode } from 'react';
import { AccessorGetPost } from '../../../accessor/AccessorGetPost';
import { cache } from '../../../cache';
import { Grid, Typography } from '../../../component/display';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutContentText,
  PageLayoutFooter,
  PageLayoutHeader,
  PageLayoutHelmet,
} from '../../../component/page_layout';
import { PostAuthor, PostDate } from '../../../component/post';
import { RedirectNotFound } from '../../../router';
import { PageBlogMarkdown } from '../PageBlogMarkdown';
import { Posts } from '../posts';

const logger = createLogger('code-null');

const PageBlogPostLayout: FunctionComponent<{
  head: {
    title: string;
    keywords: string;
    description: string;
  };
  children: ReactNode;
}> = ({ head, children }) => {
  return (
    <>
      <PageLayoutHelmet>
        <title>{head.title} | CodeNull</title>
        <meta
          name="keywords"
          content={`CodeNull,software,engineering,technology,blog,post${
            head.keywords ? `,${head.keywords}` : ''
          }`}
        />
        <meta name="description" content={head.description} />
      </PageLayoutHelmet>

      <PageLayoutHeader />

      <PageLayoutContent>
        <PageLayoutContentText>{children}</PageLayoutContentText>
      </PageLayoutContent>

      <PageLayoutFooter />
    </>
  );
};

export const PageBlogPost: FunctionComponent<{
  id: string;
}> = ({ id }) => {
  // Does the post exist?
  if (!(id in Posts)) {
    logger.error(`No post with name ${id}.`);
    return <RedirectNotFound />;
  }

  return (
    <PageLayout>
      <AccessorGetPost cache={() => cache} args={{ name: id }}>
        {({ data }) => {
          // Empty.
          if (!data) {
            // TODO: Trigger sentry error since this should never happen.
            return (
              <PageBlogPostLayout
                head={{
                  title: 'Not Found',
                  keywords: '',
                  description: `Blog post '${id}' not found.`,
                }}
              >
                <Grid container gap={16}>
                  <Grid item xs={12}>
                    <Grid container gap={8}>
                      <Grid item xs={12}>
                        <Typography variant="titleXl">
                          <b>No post content available for this post.</b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </PageBlogPostLayout>
            );
          }

          // Happy.
          return (
            <PageBlogPostLayout
              head={{
                title: data.frontmatter.title,
                keywords: data.frontmatter.keywords.join(','),
                description: data.frontmatter.description,
              }}
            >
              <Grid container gap={16}>
                <Grid item xs={12}>
                  <Grid container gap={8}>
                    <Grid item xs={12}>
                      {/* Title */}
                      <Typography id={data.frontmatter.title} variant="titleXl">
                        {data.frontmatter.title}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container style={{ alignItems: 'center' }}>
                        {/* Author */}
                        <Grid item xs={6} style={{ height: '100%' }}>
                          <PostAuthor
                            email={data.frontmatter.email}
                            author={data.frontmatter.author}
                            timeToRead={data.frontmatter.timeToRead}
                          />
                        </Grid>

                        {/* Date */}
                        <Grid
                          item
                          xs={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Typography variant="caption">
                            <PostDate date={data.frontmatter.date} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Content */}
                <Grid item xs={12}>
                  <PageBlogMarkdown>{data.content}</PageBlogMarkdown>
                </Grid>
              </Grid>
            </PageBlogPostLayout>
          );
        }}
      </AccessorGetPost>
    </PageLayout>
  );
};
