import React, { FunctionComponent } from 'react';
import { Grid, Typography } from '../component/display';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutContentText,
  PageLayoutFooter,
  PageLayoutHeader,
  PageLayoutHelmet,
} from '../component/page_layout';

export const PageError: FunctionComponent<{ error: string }> = ({ error }) => {
  return (
    <PageLayout>
      <PageLayoutHelmet>
        <title>Software Engineering Blog | CodeNull</title>
        <meta name="keywords" content="CodeNull,software,engineering,blog" />
        <meta
          name="description"
          content="Latest software engineering blog posts."
        />
      </PageLayoutHelmet>
      <PageLayoutHeader />

      <PageLayoutContent>
        <PageLayoutContentText>
          <Grid container gap={16}>
            <Grid item xs={12}>
              <Grid container gap={8}>
                <Grid item xs={12}>
                  <Typography variant="titleXl">Error</Typography>
                </Grid>

                <Grid item xs={12}>
                  {error}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PageLayoutContentText>
      </PageLayoutContent>

      <PageLayoutFooter />
    </PageLayout>
  );
};
