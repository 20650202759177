const RUNTIME_ENV =
  process.env.REACT_APP_RUNTIME_ENV !== 'production'
    ? 'development'
    : 'production';

// Sentry
const SENTRY_ENABLED = process.env.REACT_APP_SENTRY_ENABLED === 'true';
const SENTRY_ENDPOINT =
  'https://478b77d3d30a40ea9c00f92fdca03097@o4504491899748352.ingest.sentry.io/4504491904270336';

export const Config = {
  RUNTIME_ENV,

  // Sentry
  SENTRY_ENABLED,
  SENTRY_ENDPOINT,
};
