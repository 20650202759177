import { createLogger } from '@null-studios/universal-logger';
import { FunctionComponent } from 'react';
import { ScreenSize, useScreenSize } from './component/display/use_screen_size';
import version from './version.json';

const logger = createLogger('code-null');

/*
 * Prints application details.
 */
export const ApplicationDetails: FunctionComponent = () => {
  const isXs = useScreenSize(ScreenSize.ExtraSmall);
  const isSm = useScreenSize(ScreenSize.Small);
  const isMd = useScreenSize(ScreenSize.Medium);
  const isLg = useScreenSize(ScreenSize.Large);
  const isXl = useScreenSize(ScreenSize.ExtraLarge);

  logger.info('Application Details');
  logger.info('-------------------');
  logger.info(`Version Information:\n${JSON.stringify(version, null, '  ')}`);
  logger.info(
    `Screen Resolution:\n${JSON.stringify(
      {
        width: window.screen.width,
        height: window.screen.height,
      },
      null,
      '  ',
    )}`,
  );
  logger.info(
    `Page Resolution:\n${JSON.stringify(
      {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      null,
      '  ',
    )}`,
  );
  logger.info(
    `Screen Size:\n  ${
      isXs
        ? 'XS'
        : isSm
        ? 'SM'
        : isMd
        ? 'MD'
        : isLg
        ? 'LG'
        : isXl
        ? 'XL'
        : 'N/A'
    }`,
  );

  return null;
};
