// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageLayoutContentText_content__YK-lU {\n  display: flex;\n  flex-direction: column;\n  margin-left: 1em;\n  margin-right: 1em;\n  padding-top: 2em;\n}\n", "",{"version":3,"sources":["webpack://./src/component/page_layout/PageLayoutContentText.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  margin-left: 1em;\n  margin-right: 1em;\n  padding-top: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "PageLayoutContentText_content__YK-lU"
};
export default ___CSS_LOADER_EXPORT___;
