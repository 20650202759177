// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageLayoutCenter_content__BlEKi {\n  display: flex;\n  flex-direction: column;\n}\n\n.PageLayoutCenter_screenSizeXs__kEV1f {\n  margin-left: 0em;\n  margin-right: 0em;\n}\n\n.PageLayoutCenter_screenSizeSm__f1Xiq {\n  margin-left: 0em;\n  margin-right: 0em;\n}\n\n.PageLayoutCenter_screenSizeMd__kqtQR {\n  margin-left: 8em;\n  margin-right: 8em;\n}\n\n.PageLayoutCenter_screenSizeLg__-Xxuy {\n  margin-left: 12em;\n  margin-right: 12em;\n}\n\n.PageLayoutCenter_screenSizeXl__b-L6Y {\n  margin-left: 20em;\n  margin-right: 20em;\n}\n", "",{"version":3,"sources":["webpack://./src/component/page_layout/PageLayoutCenter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n}\n\n.screenSizeXs {\n  margin-left: 0em;\n  margin-right: 0em;\n}\n\n.screenSizeSm {\n  margin-left: 0em;\n  margin-right: 0em;\n}\n\n.screenSizeMd {\n  margin-left: 8em;\n  margin-right: 8em;\n}\n\n.screenSizeLg {\n  margin-left: 12em;\n  margin-right: 12em;\n}\n\n.screenSizeXl {\n  margin-left: 20em;\n  margin-right: 20em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "PageLayoutCenter_content__BlEKi",
	"screenSizeXs": "PageLayoutCenter_screenSizeXs__kEV1f",
	"screenSizeSm": "PageLayoutCenter_screenSizeSm__f1Xiq",
	"screenSizeMd": "PageLayoutCenter_screenSizeMd__kqtQR",
	"screenSizeLg": "PageLayoutCenter_screenSizeLg__-Xxuy",
	"screenSizeXl": "PageLayoutCenter_screenSizeXl__b-L6Y"
};
export default ___CSS_LOADER_EXPORT___;
