import React, { CSSProperties, FunctionComponent } from 'react';
import classes from './HorizontalRule.module.css';

/*
 * Adds a horizontal rule or `<hr>`.
 */
export const HorizontalRule: FunctionComponent<{
  className?: string;
  style?: CSSProperties;
}> = ({ className, style }) => {
  return (
    <hr
      className={`${classes.hr} ${className}`}
      style={{
        ...style,
      }}
    />
  );
};
