import { AccessorQuery } from 'data-accessor';
import { query } from './api';

export interface CacheStore extends AccessorQuery.AccessorQueryCacheStore {
  posts: Map<string, query.Post>;
  postEntries: Map<string, query.PostEntry>;
}

export const createCacheStore = (): CacheStore => {
  const cache = {
    ...AccessorQuery.createCache(setter => {
      setter(cache);
    }),
    posts: new Map<string, query.Post>(),
    postEntries: new Map<string, query.PostEntry>(),
  };

  return cache;
};

// TODO: Put this in a provider.
export const cache = createCacheStore();
