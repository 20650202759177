import { createRoot } from 'react-dom/client';
import React from 'react';
import './index.global.css';
import * as sentry from './sentry';
import { Application } from './Application';
import { Config } from './configuration';

const main = () => {
  // Sentry.
  sentry.initialize();

  // Console.
  const originalConsoleError = console.error;
  console.error = (...args) => {
    const arg0 = args[0];
    // Blacklist
    // - React validateDOMNesting: Markdown will render invalid DOM sometimes.
    if (typeof arg0 === 'string' && arg0.indexOf('validateDOMNesting') >= 0)
      return;
    originalConsoleError.apply(this, args);
  };

  // React.
  const root = document.getElementById('root') as void | HTMLDivElement;
  if (!root) {
    console.error(`Unable to find 'root'.`);
    return;
  }
  const reactRoot = createRoot(root);
  reactRoot.render(
    Config.RUNTIME_ENV === 'production' ? (
      <Application />
    ) : (
      <React.StrictMode>
        <Application />
      </React.StrictMode>
    ),
  );
};

document.addEventListener('DOMContentLoaded', main);
