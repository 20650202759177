import { Marker } from '@null-studios/browser-router';
import React, { FunctionComponent, ReactNode } from 'react';
import { BiLink } from 'react-icons/bi';
import { Link } from '../link';
import { Variant } from './types';
import classes from './TypographyAnchor.module.css';

/*
 * An anchor link for certain typography variants.
 */
export const TypographyAnchor: FunctionComponent<{
  anchor: string;
  variant: Variant;
  children?: ReactNode;
}> = ({ anchor, variant, children }) => {
  if (variant !== 'titleXl' && variant !== 'titleLg' && variant !== 'title') {
    throw new Error(`Invalid variant '${variant}'.`);
  }

  // Create href for anchor.
  const url = new URL(window.location.href);
  url.hash = `#${anchor}`;

  return (
    <Marker id={anchor}>
      <Link
        alt="Anchor"
        href={`${url.pathname}${url.hash}`}
        className={classes.titleAnchor}
      >
        {children || <BiLink style={{ fontSize: '1em' }} />}
      </Link>
    </Marker>
  );
};
