import { AccessorQuery } from 'data-accessor';
import { query } from '../api';
import { CacheStore } from '../cache';

/*
 * Get post entries.
 */
export const AccessorGetPostEntries = AccessorQuery.createComponent<
  CacheStore,
  query.QueryGetPostEntriesRequest,
  query.QueryGetPostEntriesResponse,
  query.PostEntry[]
>({
  debug: false,
  constraints: { enforce: false },
  cache: {
    duration: 1000 * 60 * 10, // 10min
    isPrimableFromCache: true,
    id: ({ args: _args }) => `getPostEntries`,
    set: ({ cache, response }): { data: query.PostEntry[] } => {
      const store = cache();

      // Parse.
      const postEntries = response.data.postEntries;
      for (const postEntry of postEntries) {
        // Set cache.
        store.postEntries.set(postEntry.name, postEntry);
      }

      return { data: postEntries };
    },
    get: ({
      cache,
      args: _args,
    }): AccessorQuery.AccessorQueryResult<query.PostEntry[]> | null => {
      const store = cache();

      // Get cache.
      const cacheResult = Array.from(store.postEntries.values());

      // Do we have a cache result?
      if (cacheResult.length > 0) {
        return { data: cacheResult };
      }

      return null;
    },
  },
  query: query.getBlogPostEntries,
});
