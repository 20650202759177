// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListItem_item__502jC {\n  font-family: var(--theme-font-family-2);\n  margin-bottom: 0.5em;\n}\n.ListItem_item__502jC:last-child {\n  margin-bottom: 0em;\n}\n.ListItem_item__502jC::marker { }\n", "",{"version":3,"sources":["webpack://./src/component/display/list/ListItem.module.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,oBAAoB;AACtB;AACA;EACE,kBAAkB;AACpB;AACA,gCAAgB","sourcesContent":[".item {\n  font-family: var(--theme-font-family-2);\n  margin-bottom: 0.5em;\n}\n.item:last-child {\n  margin-bottom: 0em;\n}\n.item::marker { }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "ListItem_item__502jC"
};
export default ___CSS_LOADER_EXPORT___;
