import React, {
  FunctionComponent,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactNode,
  ReactPortal,
} from 'react';

/*
 * Parse out a string into parts.
 * First part being all the words except the last one.
 * Second part is the last word.
 *
 * Example:
 *   $ parseStringEnding({ input: "Hello Beautiful World!" });
 *   ["Hello Beautiful ", "World!"]
 *
 *   $ parseStringEnding({ input: "Hello" });
 *   [null, "Hello"]
 */
const parseStringEnding = ({
  input,
}: {
  input: string;
}): [null | string, string] => {
  const contentParts = input.split(' ');
  if (contentParts.length > 1) {
    return [
      contentParts.slice(0, contentParts.length - 1).join(' ') + ' ',
      contentParts[contentParts.length - 1],
    ];
  } else {
    return [null, input];
  }
};

/*
 * Wrap component that will attach the second to last child to the last child.
 */
export const TypographyWrapAttach: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  // Make sure text was provided.
  let childrenStringStart: null | string = null;
  let childrenStringEnd:
    | null
    | string
    | number
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal
    | ReactNode = null;

  const childrenNormalized = React.Children.toArray(children);
  if (childrenNormalized.length > 1) {
    const childrenSecondToLast =
      childrenNormalized[childrenNormalized.length - 2];
    if (typeof childrenSecondToLast === 'string') {
      const [p1, p2] = parseStringEnding({
        input: childrenSecondToLast,
      });
      childrenStringStart = p1;
      childrenStringEnd = p2;
    }
  }

  // No wrapping needed.
  // Make sure it sticks with an anchor if one is provided.
  if (childrenStringEnd === null) {
    childrenStringEnd = childrenNormalized;
  }

  return (
    <>
      {childrenStringStart ? (
        <>
          <span style={{ display: 'inline' }}>{childrenStringStart} </span>
          <span
            style={{
              display: 'inline-flex',
              whiteSpace: 'nowrap',
            }}
          >
            {childrenStringEnd}
            {childrenNormalized[childrenNormalized.length - 1]}
          </span>
        </>
      ) : (
        <span style={{ display: 'inline-flex' }}>{childrenStringEnd}</span>
      )}
    </>
  );
};
