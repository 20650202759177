import { PageAbout } from '../page/about/PageAbout';
import { PageBlog } from '../page/blog/PageBlog';
import { PageBlogPost } from '../page/blog/post/PageBlogPost';
import { PageSitemap } from '../page/sitemap/PageSitemap';
import { EnumRoute } from './types';
import pageSitemapXml from '../page/sitemap/sitemap.xml';

// This is used because the sitemap generator can't pull in this configuration
// easily. I tried using ts-node but the asset extensions like '.md', '.txt', etc
// break and there's no easy way around it. Splitting the route destinations out
// allows the route paths to be parsed without the destinations.
export const Route: {
  [Property in EnumRoute]:
    | {
        type: 'redirect';
        isExternal?: boolean;
        destination: string;
      }
    | {
        type: 'page';
        destination: React.ComponentType<any>;
      };
} = {
  // Pages.
  [EnumRoute.About]: { type: 'page', destination: PageAbout },
  [EnumRoute.Blog]: {
    type: 'page',
    destination: PageBlog,
  },
  [EnumRoute.BlogPost]: {
    type: 'page',
    destination: PageBlogPost,
  },
  [EnumRoute.Sitemap]: {
    type: 'page',
    destination: PageSitemap,
  },

  // Redirects.
  [EnumRoute.RedirectSitemapXml]: {
    type: 'redirect',
    isExternal: true,
    destination: pageSitemapXml,
  },
  [EnumRoute.RedirectBlog]: {
    type: 'redirect',
    isExternal: false,
    destination: '/blog',
  },
  [EnumRoute.RedirectAbout]: {
    type: 'redirect',
    isExternal: true,
    destination: '/about',
  },
};
