import { createLogger } from '@null-studios/universal-logger';
import { useCallback } from 'react';
import { useNavigation } from './provider';

const logger = createLogger('browser-router');

/*
 * Provides functionality to navigate the user.
 */
export const useNavigate: () => (configuration: {
  isExternal?: boolean;
  to: string;
  replace?: boolean;
}) => void = () => {
  const navigation = useNavigation();

  return useCallback(
    ({
      isExternal,
      to,
      replace,
    }: {
      isExternal?: boolean;
      to: string;
      replace?: boolean;
    }) => {
      // Navigation ready for window events?
      if (!navigation.isReady) {
        return;
      }
      logger.info(`Navigate: to='${to}' isExternal=${isExternal}.`);

      // External route?
      if (isExternal) {
        window.location.href = to;
      } else {
        // Replace current state?
        // Allows for redirects to be excluded from history.
        if (replace) {
          window.history.replaceState({}, '', to);
        } else {
          window.history.pushState({}, '', to);
        }

        // Indicate to the window that the history changed manually.
        window.dispatchEvent(new PopStateEvent('popstate'));
      }
    },
    [navigation],
  );
};
