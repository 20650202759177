import { Posts } from '../page/blog/posts';
import { EnumRoute } from './types';

export const RoutePath: {
  name: string;
  path: string;
  destination: [string, any?];
}[] = [
  // Pages.
  {
    name: 'About',
    path: '/about',
    destination: [EnumRoute.About],
  },
  { name: 'Blog', path: '/blog', destination: [EnumRoute.Blog] },
  {
    name: 'Sitemap',
    path: '/sitemap',
    destination: [EnumRoute.Sitemap],
  },
  ...Object.keys(Posts).map(id => ({
    name: `Blog Post - ${Posts[id].name}`,
    // RSS uses this path implicitly.
    path: `/blog/post/${id}`,
    destination: [EnumRoute.BlogPost, { id }] as [string, any],
  })),

  // Redirects.
  {
    name: '',
    path: '/sitemap_xml',
    destination: [EnumRoute.RedirectSitemapXml],
  },
  { name: '', path: '/', destination: [EnumRoute.RedirectBlog] },
  {
    name: '',
    path: '/_business-card',
    destination: [EnumRoute.RedirectAbout],
  },
];
