// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageLayoutFooter_footer__cXwSH {\n  min-height: 100%;\n  background-color: var(--theme-color-2);\n  color: var(--theme-color-text-contrast);\n  padding: 1.0em;\n  text-decoration-thickness: 1px;\n}\n", "",{"version":3,"sources":["webpack://./src/component/page_layout/PageLayoutFooter.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,sCAAsC;EACtC,uCAAuC;EACvC,cAAc;EACd,8BAA8B;AAChC","sourcesContent":[".footer {\n  min-height: 100%;\n  background-color: var(--theme-color-2);\n  color: var(--theme-color-text-contrast);\n  padding: 1.0em;\n  text-decoration-thickness: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "PageLayoutFooter_footer__cXwSH"
};
export default ___CSS_LOADER_EXPORT___;
