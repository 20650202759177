// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TypographyAnchor_titleAnchor__NmyJT {\n  cursor: pointer;\n  display: flex;\n  font-size: 1em;\n  align-items: center;\n  height: 100%;\n  color: hsl(var(--theme-color-2-h), var(--theme-color-2-s), calc(var(--theme-color-2-l) + 5%));\n}\n.TypographyAnchor_titleAnchor__NmyJT:hover {\n  color: hsl(var(--theme-color-2-h), var(--theme-color-2-s), calc(var(--theme-color-2-l) + 45%));\n}\n\n", "",{"version":3,"sources":["webpack://./src/component/display/typography/TypographyAnchor.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,YAAY;EACZ,6FAA6F;AAC/F;AACA;EACE,8FAA8F;AAChG","sourcesContent":[".titleAnchor {\n  cursor: pointer;\n  display: flex;\n  font-size: 1em;\n  align-items: center;\n  height: 100%;\n  color: hsl(var(--theme-color-2-h), var(--theme-color-2-s), calc(var(--theme-color-2-l) + 5%));\n}\n.titleAnchor:hover {\n  color: hsl(var(--theme-color-2-h), var(--theme-color-2-s), calc(var(--theme-color-2-l) + 45%));\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleAnchor": "TypographyAnchor_titleAnchor__NmyJT"
};
export default ___CSS_LOADER_EXPORT___;
