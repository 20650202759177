import React, { FunctionComponent } from 'react';
import { AccessorGetPostEntries } from '../../accessor';
import { cache } from '../../cache';
import { Grid, Typography } from '../../component/display';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutContentText,
  PageLayoutFooter,
  PageLayoutHeader,
  PageLayoutHelmet,
} from '../../component/page_layout';
import { PageBlogPosts } from './PageBlogPosts';

export const PageBlog: FunctionComponent = () => {
  // TODO: Update to support showing:
  //       - Pagination
  return (
    <PageLayout>
      <PageLayoutHelmet>
        <title>Software Engineering Blog | CodeNull</title>
        <meta name="keywords" content="CodeNull,software,engineering,blog" />
        <meta
          name="description"
          content="Latest software engineering blog posts."
        />
      </PageLayoutHelmet>
      <PageLayoutHeader />

      <PageLayoutContent>
        <PageLayoutContentText>
          <Grid container gap={16}>
            <Grid item xs={12}>
              {/* Posts */}
              <AccessorGetPostEntries cache={() => cache} args={{}}>
                {({ data }) => {
                  if (!data) {
                    // TODO: Trigger sentry error since this should never happen.
                    return (
                      <Grid container gap={8}>
                        <Grid item xs={12}>
                          <Typography variant="titleXl">
                            No posts available right now.
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }

                  return <PageBlogPosts posts={data.slice(0, 10)} />;
                }}
              </AccessorGetPostEntries>
            </Grid>
          </Grid>
        </PageLayoutContentText>
      </PageLayoutContent>

      <PageLayoutFooter />
    </PageLayout>
  );
};
