import { useNavigation } from '@null-studios/browser-router';
import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { AnimationFade } from '../component/animation';

/*
 * Animation effect for when a user initially lands on the site.
 */
export const LandingAnimationFade: FunctionComponent<{
  duration: number;
  delay?: number;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}> = ({ duration, delay, className, style, children }) => {
  const navigation = useNavigation();

  // Did the user just land on the site?
  if (navigation.pathname.previous === null) {
    return (
      <AnimationFade
        duration={duration}
        delay={delay}
        className={className}
        style={style}
      >
        {children}
      </AnimationFade>
    );
  }

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};
