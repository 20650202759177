import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';

import { ScreenSize, useScreenSizeValue } from '../display/use_screen_size';
import classes from './PageLayoutCenter.module.css';

const classScreenSize: { [Property in ScreenSize]: string } = {
  [ScreenSize.ExtraSmall]: classes.screenSizeXs,
  [ScreenSize.Small]: classes.screenSizeSm,
  [ScreenSize.Medium]: classes.screenSizeMd,
  [ScreenSize.Large]: classes.screenSizeLg,
  [ScreenSize.ExtraLarge]: classes.screenSizeXl,
};

export const PageLayoutCenter: FunctionComponent<{
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}> = ({ className, style, children }) => {
  const screenSize = useScreenSizeValue();

  return (
    <div
      className={`${classes.content} ${classScreenSize[screenSize]} ${className}`}
      style={style}
    >
      {children}
    </div>
  );
};
