import React, {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BsCircleFill } from 'react-icons/bs';

import classes from './ListItem.module.css';

type Props = {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
};

export const ListItem: FunctionComponent<Props> = ({
  className,
  style,
  children,
}) => {
  const [parentNodeType, setParentNodeType] = useState<string | null>(null);
  const refElement = useRef<HTMLLIElement>(null);
  useEffect(() => {
    if (!refElement || !refElement.current) return;
    setParentNodeType(refElement.current.parentNode?.nodeName || null);
  }, []);

  return (
    <li
      ref={refElement}
      className={`${classes.item} ${className}`}
      style={{
        ...(parentNodeType === 'UL'
          ? {
              display: 'flex',
              alignItems: 'baseline',
            }
          : {}),
        ...style,
      }}
    >
      {parentNodeType === 'UL' ? (
        <BsCircleFill
          style={{
            position: 'relative',
            marginRight: '0.5em',
            width: '0.5em',
            height: '0.5em',
            minWidth: '0.5em',
            minHeight: '0.5em',
            top: '-0.1em',
          }}
        />
      ) : null}
      <span style={{ display: 'block' }}>{children}</span>
    </li>
  );
};
