import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import flattenChildren from 'react-flatten-children';
import classes from './List.module.css';

type Props = {
  variant?: 'unordered' | 'ordered';
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
};

export const List: FunctionComponent<Props> = ({
  variant,
  className,
  style,
  children,
}) => {
  // TODO: Figure out how to introspect react children in typescript.
  const childrenFlattened = React.Children.map(
    flattenChildren(children || []),
    (child: any) => {
      return child;
    },
  );

  // What kind of list are we rendering?
  if (!variant || variant === 'unordered') {
    return (
      <ul
        className={`${classes.listUnordered} ${className}`}
        style={{ ...style }}
      >
        {childrenFlattened}
      </ul>
    );
  } else {
    return (
      <ol
        className={`${classes.listOrdered} ${className}`}
        style={{ ...style }}
      >
        {childrenFlattened}
      </ol>
    );
  }
};
