import React, { CSSProperties, FunctionComponent } from 'react';

export const Image: FunctionComponent<{
  src: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  style?: CSSProperties;
}> = ({ src, width, height, className, style }) => {
  return (
    <img
      className={className}
      src={src}
      width={width}
      height={height}
      style={{
        maxWidth: '100%',
        height: 'auto',
        ...style,
      }}
    />
  );
};
