import {
  ConfigurationRouter,
  Router as BrowserRouter,
} from '@null-studios/browser-router';
import React, { FunctionComponent } from 'react';
import { default as useErrorBoundary } from 'use-error-boundary';
import { PageError } from '../page/PageError';
import { Route } from './route_page';
import { RoutePath } from './route_path';
import { EnumRoute } from './types';

// All application routes used to render different pages.
export const Configuration: ConfigurationRouter = {
  routes: RoutePath.map(routePath => {
    const routeId = routePath.destination[0];
    if (!(routeId in EnumRoute)) {
      throw new Error('Not a valid route ID.');
    }
    const route = Route[routeId as EnumRoute];

    // Route Page.
    if (route.type === 'page') {
      const Component = route.destination;
      const props = routePath.destination[1];
      const configurationRoute: ConfigurationRouter['routes'][0] = {
        ...routePath,
        type: route.type,
        destination: () => {
          const { ErrorBoundary, error } = useErrorBoundary();

          // Error State
          if (error) {
            console.error(error);
            return <PageError error={error} />;
          }

          return (
            <ErrorBoundary>
              <Component {...props} />
            </ErrorBoundary>
          );
        },
      };
      return configurationRoute;
    }
    // Route Redirect.
    else if (route.type === 'redirect') {
      const configurationRoute: ConfigurationRouter['routes'][0] = {
        ...routePath,
        ...route,
      };
      return configurationRoute;
    } else {
      throw new Error(`Route type invalid for route '${routePath.name}'.`);
    }
  }),
};

/*
 * Application router.
 */
export const Router: FunctionComponent = () => {
  return <BrowserRouter configuration={Configuration} />;
};
