import {
  default as React,
  FunctionComponent,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { Context } from './Context';
import { Navigator } from './Navigator';

/*
 * Provider for navigational context.
 */
export const ProviderNavigation: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [isReady, setIsReady] = useState<boolean>(false);

  // Locatoin details.
  const [previousLocationHash, setPreviousLocationHash] = useState<
    null | string
  >(null);
  const [locationHash, setLocationHash] = useState<string>(
    window.location.hash,
  );
  const [previousLocationPathName, setPreviousLocationPathName] = useState<
    null | string
  >(null);
  const [locationPathName, setLocationPathName] = useState<string>(
    window.location.pathname,
  );

  /*
   * Handles setting the location properties and
   * keeping track of the previous locatoin properites.
   */
  const onSetPathname = useCallback(
    ({ pathname }: { pathname: string }) => {
      if (locationPathName === pathname) {
        return;
      }
      setLocationPathName(pathname);
      setPreviousLocationPathName(locationPathName);
    },
    [locationPathName],
  );
  const onSetHash = useCallback(
    ({ hash }: { hash: string }) => {
      if (locationHash === hash) {
        return;
      }
      setLocationHash(hash);
      setPreviousLocationHash(locationHash);
    },
    [locationHash],
  );

  return (
    <Context.Provider
      value={{
        isReady,
        setIsReady,

        // Location details.
        pathname: {
          previous: previousLocationPathName,
          current: locationPathName,
        },
        setPathname: onSetPathname,
        hash: {
          previous: previousLocationHash,
          current: locationHash,
        },
        setHash: onSetHash,
      }}
    >
      <Navigator />
      {children}
    </Context.Provider>
  );
};
