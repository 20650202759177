// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageLayoutHeader_navigation__IzWr\\+ {\n  background-color: var(--theme-color-2);\n  height: 4.25em;\n}\n\n.PageLayoutHeader_navigationStart__FV1mU {\n  font-size: 4em;\n  margin-left: 0.25em;\n  margin-right: 0.1em;\n  display: flex;\n}\n\n.PageLayoutHeader_logo__WHz3c {\n  transition: font-size 1s ease-in-out;\n}\n\n.PageLayoutHeader_navigationEnd__KMfOn {\n  margin-right: 1em;\n  display: flex;\n  align-items: center;\n}\n\n.PageLayoutHeader_navigationEnd__KMfOn a:not(:last-child) {\n  margin-right: 0.5em;\n}\n\n.PageLayoutHeader_navigationIcon__Xgtry {\n  font-size: inherit;\n  color: white;\n  display: flex;\n  align-items: center;\n  transition: font-size 1s ease-in-out;\n}\n\n", "",{"version":3,"sources":["webpack://./src/component/page_layout/PageLayoutHeader.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,oCAAoC;AACtC","sourcesContent":[".navigation {\n  background-color: var(--theme-color-2);\n  height: 4.25em;\n}\n\n.navigationStart {\n  font-size: 4em;\n  margin-left: 0.25em;\n  margin-right: 0.1em;\n  display: flex;\n}\n\n.logo {\n  transition: font-size 1s ease-in-out;\n}\n\n.navigationEnd {\n  margin-right: 1em;\n  display: flex;\n  align-items: center;\n}\n\n.navigationEnd a:not(:last-child) {\n  margin-right: 0.5em;\n}\n\n.navigationIcon {\n  font-size: inherit;\n  color: white;\n  display: flex;\n  align-items: center;\n  transition: font-size 1s ease-in-out;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": "PageLayoutHeader_navigation__IzWr+",
	"navigationStart": "PageLayoutHeader_navigationStart__FV1mU",
	"logo": "PageLayoutHeader_logo__WHz3c",
	"navigationEnd": "PageLayoutHeader_navigationEnd__KMfOn",
	"navigationIcon": "PageLayoutHeader_navigationIcon__Xgtry"
};
export default ___CSS_LOADER_EXPORT___;
