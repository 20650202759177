import React, { Fragment, FunctionComponent } from 'react';
import { PostEntry } from '../../api/query';
import {
  Grid,
  HorizontalRule,
  Link,
  Typography,
} from '../../component/display';
import { PostAuthor, PostDate } from '../../component/post';
import { PageBlogMarkdown } from './PageBlogMarkdown';

/*
 * Renders posts entrys.
 */
export const PageBlogPosts: FunctionComponent<{
  posts: PostEntry[];
}> = ({ posts }) => {
  return (
    <Grid container columns={12} gap={24}>
      {posts.map(post => (
        <Fragment key={post.name}>
          <Grid item xs={12}>
            <PageBlogPostEntry post={post} />
          </Grid>

          <Grid item xs={12}>
            <HorizontalRule />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};

/*
 * Renders post entry consisisting of:
 * - title
 * - author
 * - date created
 * - time-to-read
 * - excerpt
 */
const PageBlogPostEntry: FunctionComponent<{ post: PostEntry }> = ({
  post,
}) => {
  return (
    <article>
      <Grid container gap={16}>
        <Grid item xs={12}>
          <Grid container gap={8}>
            <Grid item xs={12}>
              {/* Title */}
              <Link href={`/blog/post/${post.id}`}>
                <Typography variant="titleXl">
                  {post.frontmatter.title}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12}>
              <Grid container style={{ alignItems: 'center' }}>
                {/* Author */}
                <Grid item xs={6} style={{ height: '100%' }}>
                  <PostAuthor
                    email={post.frontmatter.email}
                    author={post.frontmatter.author}
                    timeToRead={post.frontmatter.timeToRead}
                  />
                </Grid>

                {/* Date */}
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography variant="caption">
                    <PostDate date={post.frontmatter.date} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Excerpt */}
        <Grid item xs={12}>
          <PageBlogMarkdown>{post.frontmatter.excerpt}</PageBlogMarkdown>
        </Grid>
      </Grid>
    </article>
  );
};
