import React, { createContext, FunctionComponent, ReactNode } from 'react';
import { useLocalStorage } from '../../hook';
import { useMediaQuery } from '../display';
import classes from './ProviderTheme.module.css';

export enum EnumTheme {
  LIGHT_GRAY = 'LIGHT_GRAY',
}

export const ProviderThemeContext = createContext<null | { theme: EnumTheme }>(
  null,
);

export const ProviderTheme: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [theme, _setTheme] = useLocalStorage('theme', EnumTheme.LIGHT_GRAY);
  const isBelowMin = useMediaQuery('(max-width: 450px)');

  let classTheme;
  if (theme === EnumTheme.LIGHT_GRAY) {
    classTheme = classes.themeLightGray;
  }
  let classBelowMin;
  if (isBelowMin) {
    classBelowMin = classes.belowMin;
  }

  return (
    <ProviderThemeContext.Provider value={{ theme }}>
      <div className={`${classes.theme} ${classTheme}`}>
        <div className={`${classes.content} ${classBelowMin}`}>{children}</div>
      </div>
    </ProviderThemeContext.Provider>
  );
};
