import React, {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { flushSync } from 'react-dom';
import { v4 as uuid } from 'uuid';
import { copy } from '../../copy';
import classes from './Copy.module.css';

const COPY_INDICATOR_TIMEOUT = 1000 * 3; // Matches the `animation-duration: 3s` CSS.

/*
 * Copy functional wrapper.
 *
 * When wrapped around a component this can be used to populate the user's
 * clipboard with specific content for the component.
 *
 * TODO: Inject keyframes.
 *
 *    const injectStyle = (style) => {
 *      const styleElement = document.createElement('style');
 *      let styleSheet = null;
 *
 *      document.head.appendChild(styleElement);
 *
 *      styleSheet = styleElement.sheet;
 *
 *      styleSheet.insertRule(style, styleSheet.cssRules.length);
 *    };
 *
 *    export default injectStyle;
 */
export const Copy: FunctionComponent<{
  content: string;
  direction?: 'up' | 'down' | 'left' | 'right';
  textCopied: JSX.Element;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}> = ({
  content,
  direction: inputDirection,
  textCopied,
  children,
  className,
  style,
}) => {
  const [indicatorPopovers, setIndicatorPopovers] = useState<
    { id: string; direction: string; timer: any }[]
  >([]);

  const direction = !inputDirection ? 'up' : inputDirection;

  const onClick = useCallback(() => {
    const success = copy(content);
    if (!success) {
      return;
    }

    // Indicator popovers should have their own timer decay.
    const timer = setTimeout(() => {
      flushSync(() => {
        setIndicatorPopovers(indicatorPopovers => {
          return [...indicatorPopovers].slice(1);
        });
      });
    }, COPY_INDICATOR_TIMEOUT);

    flushSync(() => {
      setIndicatorPopovers(indicatorPopovers => [
        ...indicatorPopovers,
        {
          id: uuid(),
          direction,
          timer,
        },
      ]);
    });
  }, [direction]);

  return (
    <span
      onClick={onClick}
      className={`${classes.root} ${className}`}
      style={style}
    >
      {/* Indicators */}
      {indicatorPopovers.map(indicatorPopover => (
        <span
          key={indicatorPopover.id}
          className={`${classes.popover}`}
          style={{
            animationName:
              indicatorPopover.direction === 'up'
                ? classes.fadeUpAndOut
                : indicatorPopover.direction === 'down'
                ? classes.fadeDownAndOut
                : indicatorPopover.direction === 'left'
                ? classes.fadeLeftAndOut
                : classes.fadeRightAndOut,
          }}
        >
          {textCopied}
        </span>
      ))}

      {children}
    </span>
  );
};
