import React, { FunctionComponent } from 'react';
import { RiTimeLine } from 'react-icons/ri';
import { Typography, TypographySpacer } from '../display';
import { GravatarProfile } from '../gravatar';

export const PostAuthor: FunctionComponent<{
  email: string;
  author: string;
  timeToRead: string;
}> = ({ email, author, timeToRead }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <GravatarProfile
        email={email}
        style={{
          width: '1.25em',
          height: '1.25em',
          paddingRight: '0.2em',
        }}
      />
      <Typography
        variant="caption"
        style={{ display: 'flex', alignItems: 'center', height: '100%' }}
      >
        {author}{' '}
        <TypographySpacer
          style={{
            borderLeftColor:
              'hsl(var(--theme-color-text-hs), calc(var(--theme-color-text-l) + 80%))',
          }}
        />
        <RiTimeLine style={{ marginRight: '0.2em' }} />
        {timeToRead}
      </Typography>
    </div>
  );
};
