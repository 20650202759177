import { Navigate } from '@null-studios/browser-router';
import React, { FunctionComponent } from 'react';
import { AiFillLinkedin, AiFillTwitterCircle } from 'react-icons/ai';
import { BsGithub, BsReddit, BsYoutube } from 'react-icons/bs';
import { Fa500Px, FaRust } from 'react-icons/fa';
import { ImNpm } from 'react-icons/im';
import { Logo } from '../../Logo';
import { LandingAnimationFade } from '../../router';
import { PageLayoutCenter } from './PageLayoutCenter';
import classes from './PageLayoutHeader.module.css';

export const PageLayoutHeader: FunctionComponent = () => {
  const sizeOffset = 0.65;

  return (
    <LandingAnimationFade duration={0.5} delay={0}>
      <header>
        <nav
          role="navigation"
          aria-label="main navigation"
          className={classes.navigation}
        >
          <PageLayoutCenter
            style={{
              height: '100%',
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <div className={classes.navigationStart}>
              <Navigate alt="CodeNull Logo" href="/">
                <LandingAnimationFade duration={0.5} delay={0.25}>
                  <Logo
                    className={classes.logo}
                    style={{
                      fill: 'white',
                      width: '12em',
                      height: '4em',
                    }}
                  />
                </LandingAnimationFade>
              </Navigate>
            </div>

            <LandingAnimationFade
              duration={0.5}
              delay={0.25}
              className={classes.navigationEnd}
            >
              <Navigate
                alt="My GitHub"
                isExternal
                href="https://github.com/Nate-Wilkins"
              >
                <BsGithub
                  className={classes.navigationIcon}
                  style={{ fontSize: `${1.3 + sizeOffset}em` }}
                />
              </Navigate>

              <Navigate
                alt="My Twitter"
                isExternal
                href="https://twitter.com/_natewilkins"
              >
                <AiFillTwitterCircle
                  className={classes.navigationIcon}
                  style={{ fontSize: `${1.4 + sizeOffset}em` }}
                />
              </Navigate>

              <Navigate
                alt="My LinkedIn"
                isExternal
                href="https://www.linkedin.com/in/nathanielwilkins"
              >
                <AiFillLinkedin
                  className={classes.navigationIcon}
                  style={{ fontSize: `${1.45 + sizeOffset}em` }}
                />
              </Navigate>

              <Navigate
                alt="My 500px"
                isExternal
                href="https://500px.com/p/Nate-Wilkins?view=photos"
              >
                <Fa500Px
                  className={classes.navigationIcon}
                  style={{ fontSize: `${1.05 + sizeOffset}em` }}
                />
              </Navigate>

              <Navigate
                alt="My Reddit"
                isExternal
                href="https://www.reddit.com/user/nate-wilkins"
              >
                <BsReddit
                  className={classes.navigationIcon}
                  style={{ fontSize: `${1.1 + sizeOffset}em` }}
                />
              </Navigate>

              <Navigate
                alt="My YouTube"
                isExternal
                href="https://www.youtube.com/@nate-wilkins"
              >
                <BsYoutube
                  className={classes.navigationIcon}
                  style={{ fontSize: `${1.2 + sizeOffset}em` }}
                />
              </Navigate>

              <Navigate
                alt="My NPM"
                isExternal
                href="https://www.npmjs.com/~nate-wilkins"
              >
                <ImNpm
                  className={classes.navigationIcon}
                  style={{ fontSize: `${1.08 + sizeOffset}em` }}
                />
              </Navigate>

              <Navigate
                alt="My Crates"
                isExternal
                href="https://crates.io/users/Nate-Wilkins"
              >
                <FaRust
                  className={classes.navigationIcon}
                  style={{ fontSize: `${1.3 + sizeOffset}em` }}
                />
              </Navigate>
            </LandingAnimationFade>
          </PageLayoutCenter>
        </nav>
      </header>
    </LandingAnimationFade>
  );
};
