import * as Sentry from '@sentry/react';
import { createLogger } from '@null-studios/universal-logger';
import { BrowserTracing } from '@sentry/tracing';
import { Config } from './configuration';

const logger = createLogger('sentry');

export const initialize = (): void => {
  if (!Config.SENTRY_ENABLED) return;

  // TODO: Sentry releases.
  //   https://docs.sentry.io/product/releases/?_ga=2.203326033.1560867296.1644785434-624597184.1644465990&_gac=1.59877983.1644785457.CjwKCAiA9aKQBhBREiwAyGP5lXzhUJeQFhUmfwaTZt4JqqbKukXxuhi3ewWNJ6yWSj0hFQAFvbpfcxoC358QAvD_BwE
  logger.info('Initialize.');

  // Sentry breaks logging when initialized.
  // eslint-disable-next-line
  const consoleLog = console.log;
  // eslint-disable-next-line
  const consoleInfo = console.info;
  // eslint-disable-next-line
  const consoleError = console.error;
  // eslint-disable-next-line
  // @ts-ignore
  // eslint-disable-next-line
  delete console.log;
  // eslint-disable-next-line
  // @ts-ignore
  // eslint-disable-next-line
  delete console.info;
  // eslint-disable-next-line
  // @ts-ignore
  // eslint-disable-next-line
  delete console.error;

  Sentry.init({
    dsn: Config.SENTRY_ENDPOINT,
    environment: 'production',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
  });

  // eslint-disable-next-line
  console.log = consoleLog;
  // eslint-disable-next-line
  console.info = consoleInfo;
  // eslint-disable-next-line
  console.error = consoleError;

  logger.info('Done initializing.');
};

export const captureException = (e: unknown): void => {
  if (!Config.SENTRY_ENABLED) return;

  Sentry.captureException(e);
};
