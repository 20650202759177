import React, { FunctionComponent, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import flattenChildren from 'react-flatten-children';

export const PageLayoutHelmet: FunctionComponent<{
  children?: ReactNode;
}> = ({ children }) => {
  // TODO: Figure out how to introspect react children in typescript.
  const childrenFlattened = React.Children.map(
    flattenChildren(children || []),
    (child: any) => {
      return child;
    },
  );

  return <Helmet>{childrenFlattened}</Helmet>;
};
