import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string): boolean => {
  // JSDom doesn't currently have a mock for this.
  const matchMedia =
    typeof window.matchMedia !== 'undefined'
      ? window.matchMedia
      : () => ({ matches: false });

  const [matches, setMatches] = useState(matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [query]);

  return matches;
};
