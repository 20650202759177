import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from './use_navigate';

/*
 * Redirect the user on mount.
 */
export const Redirect: FunctionComponent<{
  isExternal?: boolean;
  to: string;
}> = ({ isExternal, to }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate({ isExternal, to, replace: true });
  }, [navigate, isExternal, to]);

  return null;
};
