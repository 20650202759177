import React, {
  Children,
  CSSProperties,
  FunctionComponent,
  ReactNode,
} from 'react';
import { Remark } from 'react-remark';
import remarkSimplePlantUml from '@akebifiky/remark-simple-plantuml';
import remarkPluginEmbedder from '@remark-embedder/core';
import rehypeRaw from 'rehype-raw';
import rehypeStringify from 'rehype-stringify';
import remarkPluginHtml from 'remark-html';
import remarkPluginUnwrapImages from 'remark-unwrap-images';
import {
  HorizontalRule,
  Image,
  Link,
  List,
  ListItem,
  Typography,
} from '../../component/display';
import { Assets } from './posts/assets';

// Example:
//    https://iframe.snake-threejs.netlify.app/
//
// NOTE: You want to make your own transformer if this doesn't work.
const TransformerIframeSource = {
  name: 'IframeSource',
  shouldTransform(url: string) {
    const { host } = new URL(url);

    return host.startsWith('iframe.');
  },
  async getHTML(url: string) {
    const { protocol, host, pathname, searchParams } = new URL(url);
    const newHost = host.replace('iframe.', '');
    const iframeSrc = `${protocol}//${newHost}${pathname}?${searchParams.toString()}`;

    return `<iframe tabindex="0" src="${iframeSrc}" style="width:100%; height:31.25em; border:0; border-radius: 0.25em; overflow:hidden;" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>`;
  },
};

// Example:
//    https://stackblitz.com/edit/react-ts-kl2txy?file=App.tsx&view=preview
//
// TODO: Have custom code runners besides stackblitz?
const TransformerStackBlitz = {
  name: 'StackBlitz',
  shouldTransform(url: string) {
    const { host } = new URL(url);

    return ['stackblitz.com', 'www.stackblitz.com'].includes(host);
  },
  async getHTML(url: string) {
    const { origin, pathname, searchParams } = new URL(url);
    searchParams.append('embed', '1');
    const iframeSrc = `${origin}${pathname}?${searchParams.toString()}`;

    return `<iframe tabindex="0" src="${iframeSrc}" style="width:100%; height:31.25em; border:0; border-radius: 0.25em; overflow:hidden;" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>`;
  },
};

// Example:
//   https://www.youtube.com/embed/tgbNymZ7vqY
//
const TransformerYoutube = {
  name: 'YouTube',
  shouldTransform(url: string) {
    const { host } = new URL(url);

    return ['youtube.com', 'www.youtube.com'].includes(host);
  },
  async getHTML(url: string) {
    const { origin, pathname, searchParams } = new URL(url);
    searchParams.append('embed', '1');
    const iframeSrc = `${origin}${pathname}?${searchParams.toString()}`;

    return `
      <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 1em;">
        <div style="width: 40em; height: 22.5em;">
          <iframe tabindex="0" type="text/html" width="100%" height="100%" src="${iframeSrc}&origin=${window.location.origin}" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    `;
  },
};

// Taken from:
//   https://www.danylkoweb.com/Blog/github-cards-for-your-site-JF
//
// Example:
//   https://github.com/Nate-Wilkins/react-discord-presence
//
// TODO: Add one for GitHubProfileCard.
// TODO: Add one for GitLab.
//
const TransformerGitHubRepositoryCard = {
  name: 'GitHub Repository Card',
  shouldTransform(url: string) {
    const { host, pathname } = new URL(url);

    // Match GitHub and Repository Path.
    return (
      ['github.com', 'www.github.com'].includes(host) &&
      pathname.split('/').length > 2
    );
  },
  async getHTML(url: string) {
    const { pathname } = new URL(url);

    return `
      <div style="display: flex; align-items: center; justify-content: start; margin-bottom: 1em;">
        <a href="${url}">
          <img src="https://gh-card.dev/repos${pathname}.svg?fullname=" width="100%" height="7em" />
        </a>
      </div>
    `;
  },
};

export const PageBlogMarkdown: FunctionComponent<{ children: string }> = ({
  children,
}) => {
  // Replace all asset source paths with bundled asset paths.
  let content = children;
  for (const assetSourcePath of Object.keys(Assets)) {
    content = content.replace(assetSourcePath, Assets[assetSourcePath]);
  }

  return (
    <Remark
      remarkPlugins={[
        remarkPluginUnwrapImages,
        remarkSimplePlantUml,
        // NOTE: Cannot include because it requires file system libraries not provided by the browser.
        //remarkPluginGraphviz,
        [
          remarkPluginEmbedder,
          {
            transformers: [
              TransformerStackBlitz,
              TransformerYoutube,
              TransformerGitHubRepositoryCard,
              TransformerIframeSource,
            ],
          },
        ],
        remarkPluginHtml,
      ]}
      remarkToRehypeOptions={{ allowDangerousHtml: true }}
      // TODO: Fix rehype plugins to work with typescript.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rehypePlugins={[rehypeRaw, rehypeStringify]}
      rehypeReactOptions={{
        components: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          h1({
            className,
            children,
            style,
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            // Pull out anchor text.
            let anchor = '';
            Children.map(children, child => {
              if (typeof child !== 'string') {
                return;
              }
              anchor += child;
            });

            return (
              <div style={{ marginBottom: '1.25em', marginTop: '2.5em' }}>
                <Typography
                  anchor={anchor}
                  variant="titleXl"
                  className={className}
                  style={{
                    ...style,
                  }}
                >
                  {children}
                </Typography>
              </div>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          h2({
            className,
            children,
            style,
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            // Pull out anchor text.
            let anchor = '';
            Children.map(children, child => {
              if (typeof child !== 'string') {
                return;
              }
              anchor += child;
            });

            return (
              <div style={{ marginBottom: '1.25em', marginTop: '2.5em' }}>
                <Typography
                  variant="titleLg"
                  anchor={anchor}
                  className={className}
                  style={{
                    ...style,
                  }}
                >
                  {children}
                </Typography>
              </div>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          h3({
            className,
            children,
            style,
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            return (
              <div style={{ marginBottom: '1.25em', marginTop: '1.5em' }}>
                <Typography
                  variant="title"
                  className={className}
                  style={{
                    ...style,
                  }}
                >
                  {children}
                </Typography>
              </div>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          h4({
            className,
            children,
            style,
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            return (
              <div style={{ marginBottom: '1.25em', marginTop: '2.5em' }}>
                <Typography
                  variant="captionLg"
                  className={className}
                  style={{
                    display: 'block',
                    ...style,
                  }}
                >
                  {children}
                </Typography>
              </div>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          h5({
            className,
            children,
            style,
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            return (
              <div style={{ marginBottom: '1.25em', marginTop: '2.5em' }}>
                <Typography
                  variant="caption"
                  className={className}
                  style={{
                    display: 'block',
                    ...style,
                  }}
                >
                  {children}
                </Typography>
              </div>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          h6({
            className,
            children,
            style,
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            return (
              <div style={{ marginBottom: '1.25em', marginTop: '2.5em' }}>
                <Typography
                  variant="captionSm"
                  className={className}
                  style={{
                    display: 'block',
                    ...style,
                  }}
                >
                  {children}
                </Typography>
              </div>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          p({
            className,
            children,
            style,
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            return (
              <div style={{ marginBottom: '1em' }}>
                <Typography
                  variant="body"
                  className={className}
                  style={{
                    ...style,
                  }}
                >
                  {children}
                </Typography>
              </div>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ul({
            className,
            children,
            style,
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            return (
              <div style={{ marginBottom: '1em' }}>
                <List
                  variant="unordered"
                  className={className}
                  style={{ ...style }}
                >
                  {children}
                </List>
              </div>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ol({
            className,
            children,
            style,
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            return (
              <div style={{ marginBottom: '1em' }}>
                <List
                  variant="ordered"
                  className={className}
                  style={{ ...style }}
                >
                  {children}
                </List>
              </div>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          li({
            className,
            children,
            style,
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            return (
              <ListItem
                className={className}
                style={{
                  ...style,
                }}
              >
                <Typography variant="body">{children}</Typography>
              </ListItem>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          img({
            src,
            width,
            height,
            className,
            style,
            ...props
          }: {
            src: string;
            width?: string | number;
            height?: string | number;
            className: string;
            style: CSSProperties;
          }) {
            // Force size to remove content layout shift.
            if (!width) {
              throw new Error(`Width not provided on image. Source '${src}'.`);
            }
            if (!height) {
              throw new Error(`Height not provided on image. Source '${src}'.`);
            }
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'center',
                  marginBottom: '1em',
                }}
              >
                <Image
                  src={src}
                  width={width}
                  height={height}
                  className={className}
                  style={{
                    ...style,
                  }}
                  {...props}
                />
              </div>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          em({
            className,
            children,
            style,
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            return (
              <Typography
                variant="body"
                className={className}
                style={{
                  ...style,
                  fontStyle: 'italic',
                  fontSize: 'inherit',
                }}
              >
                {children}
              </Typography>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          code({
            className,
            children,
            style,
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            const match = /language-(\w+)/.exec(className || '');
            const content = String(children).replace(/\n$/, '');
            const countLines = content.split('\n').length - 1;
            return (
              <Typography
                variant="code"
                language={match ? match[1] : ''}
                inline={!match}
                lineNumbers={countLines > 0}
                children={content}
                className={className}
                style={style}
              />
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          a({
            className,
            children,
            style,
            ...props
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            return (
              <Link isExternal className={className} style={style} {...props}>
                {children}
              </Link>
            );
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          hr({
            className,
            children,
            style,
            ...props
          }: {
            className: string;
            children: ReactNode;
            style: CSSProperties;
          }) {
            return (
              <HorizontalRule
                className={className}
                style={{
                  marginBottom: '1.5em',
                  marginTop: '1.5em',
                  ...style,
                }}
                {...props}
              />
            );
          },
        },
      }}
    >
      {content}
    </Remark>
  );
};
