import React, { FunctionComponent, ReactNode } from 'react';
import { ProviderTheme } from './component/theme';

/*
 * Root providers for the application.
 *
 * Think of this kindof like an IoC container providing base functionality for the rest of the application.
 * This component is used to bootstrap the application and to bootstrap a testing environment for component tests.
 *
 * Sets up the following:
 * - ProviderTheme
 */
export const ApplicationContainer: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  return <ProviderTheme>{children}</ProviderTheme>;
};
