import React, { CSSProperties, FunctionComponent } from 'react';

export const TypographySpacer: FunctionComponent<{
  style?: CSSProperties;
}> = ({ style }) => {
  return (
    <div
      style={{
        height: '100%',
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        borderLeft: '1px solid var(--theme-color-text)',
        ...style,
      }}
    ></div>
  );
};
