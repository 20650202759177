import { AccessorQuery } from 'data-accessor';
import { query } from '../api';
import { CacheStore } from '../cache';

/*
 * Get a single post.
 */
export const AccessorGetPost = AccessorQuery.createComponent<
  CacheStore,
  query.QueryGetPostRequest,
  query.QueryGetPostResponse,
  query.Post
>({
  debug: false,
  constraints: { enforce: false },
  cache: {
    duration: 1000 * 60 * 10, // 10min
    isPrimableFromCache: true,
    id: ({ args }) => `getBlogPost#id#${args.name}`,
    set: ({ cache, response }): { data: query.Post } => {
      const store = cache();

      // Parse.
      const post = response.data.post;

      // Set cache.
      store.posts.set(post.name, post);

      return { data: post };
    },
    get: ({
      cache,
      args,
    }): AccessorQuery.AccessorQueryResult<query.Post> | null => {
      const store = cache();

      // Get cache.
      const cacheResult = store.posts.get(args.name);

      // Do we have a cache result?
      if (typeof cacheResult !== 'undefined') {
        return { data: cacheResult };
      }

      return null;
    },
  },
  query: query.getBlogPost,
});
