import React, {
  AnchorHTMLAttributes,
  CSSProperties,
  FunctionComponent,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { useNavigate } from './use_navigate';

/*
 * Component to navigate user.
 */
export const Navigate: FunctionComponent<{
  alt?: string;
  href?: string;
  style?: CSSProperties;
  isExternal?: boolean;
  rel?: AnchorHTMLAttributes<HTMLAnchorElement>['rel'];
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  children: ReactNode;
  onClick?: () => void;
}> = ({ alt, href, style, isExternal, rel, target, children, onClick }) => {
  const navigate = useNavigate();

  const handleClick: MouseEventHandler<HTMLAnchorElement> = event => {
    // Handle onClick.
    onClick && onClick();

    // Handle href.
    if (!href) return;
    if (event.metaKey || event.ctrlKey) {
      return;
    }

    event.preventDefault();

    navigate({ to: href });
  };

  return (
    <a
      aria-label={alt}
      href={href}
      onClick={!isExternal ? handleClick : undefined}
      style={{
        cursor: 'pointer',
        fontSize: 'inherit',
        textDecoration: 'inherit',
        ...style,
      }}
      rel={rel}
      target={target}
    >
      {children}
    </a>
  );
};
