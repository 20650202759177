import React, { CSSProperties, FunctionComponent } from 'react';
import md5 from 'md5';

export const GravatarProfile: FunctionComponent<{
  width?: string;
  height?: string;
  email: string;
  style?: CSSProperties;
}> = ({ email, style, width, height }) => {
  const emailLowercase = email.toLowerCase().trim();
  const emailMd5 = md5(emailLowercase);

  return (
    <img
      alt={`Gravatar Profile ${emailLowercase}`}
      style={{
        width: 'auto',
        height: 'auto',
        maxWidth: width,
        maxHeight: height,
        borderRadius: '50%',
        boxSizing: 'content-box',
        ...style,
      }}
      width={width}
      height={height}
      src={`https://www.gravatar.com/avatar/${emailMd5}?s=250`}
    />
  );
};
