import { useContext } from 'react';
import { Context } from './Context';

/*
 * Get navigation context.
 */
export const useNavigation = () => {
  const navigation = useContext(Context);
  if (!navigation) {
    throw new Error(
      `useNavigation must be used under a 'ProviderNavigation' component.`,
    );
  }
  return navigation;
};
