import { createLogger } from '@null-studios/universal-logger';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useNavigation } from './use_navigation';

const logger = createLogger('browser-router');

/*
 * Save location when location changes.
 */
export const Navigator: FunctionComponent = () => {
  const navigation = useNavigation();

  const onLocationChange = useCallback(() => {
    navigation.setPathname({ pathname: window.location.pathname });
    navigation.setHash({ hash: window.location.hash });
  }, [navigation]);

  useEffect(() => {
    logger.info(`addEventListener('popstate', onLocationChange)`);
    window.addEventListener('popstate', onLocationChange);
    navigation.setIsReady(true);

    return () => {
      logger.info(`removeEventListener('popstate', onLocationChange)`);
      window.removeEventListener('popstate', onLocationChange);
    };
  }, [onLocationChange, navigation]);

  return null;
};
