import React, { FunctionComponent } from 'react';
import {
  Grid,
  Link,
  List,
  ListItem,
  Typography,
} from '../../component/display';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutContentText,
  PageLayoutFooter,
  PageLayoutHeader,
  PageLayoutHelmet,
} from '../../component/page_layout';
import { Configuration } from '../../router';

export const PageSitemap: FunctionComponent = () => {
  return (
    <PageLayout>
      <PageLayoutHelmet>
        <title>Sitemap | CodeNull</title>
        <meta
          name="keywords"
          content="CodeNull,sitemap,software,engineering,blog"
        />
        <meta name="description" content="Sitemap in HTML for CodeNull." />
      </PageLayoutHelmet>

      <PageLayoutHeader />

      <PageLayoutContent>
        <PageLayoutContentText>
          <Grid container gap={16}>
            <Grid item xs={12}>
              <Typography variant="titleXl">Sitemap</Typography>
            </Grid>

            <Grid item xs={12}>
              <List>
                {Configuration.routes
                  .filter(route => !!route.name)
                  .map(route => (
                    <ListItem key={route.path}>
                      <Link href={route.path}>
                        <Typography variant="body">{route.name}</Typography>
                      </Link>
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Grid>
        </PageLayoutContentText>
      </PageLayoutContent>

      <PageLayoutFooter />
    </PageLayout>
  );
};
