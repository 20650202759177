import { useState } from 'react';

// Taken from: https://usehooks.com/useLocalStorage/
export const useLocalStorage = <T,>(
  key: string,
  initialValue: T,
): [T, (v: T | ((v: T) => T)) => void] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue;
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((v: T) => T)) => {
    // Save state
    setStoredValue((v: T) => {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(v) : value;
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      return valueToStore;
    });
  };

  return [storedValue, setValue];
};
