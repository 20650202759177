import { createLogger } from '@null-studios/universal-logger';
import React, { FunctionComponent, ReactNode } from 'react';
import { useNavigation } from './provider';

const logger = createLogger('browser-router');

/*
 * Conditional render for a particular route.
 */
export const Route: FunctionComponent<{
  path: string;
  children: ReactNode;
}> = ({ path, children }) => {
  const {
    pathname: { current },
  } = useNavigation();

  logger.info(`Route Check: current('${current}') == self('${path}').`);

  // Do we need to render this route?
  if (current !== path) {
    return null;
  }

  return <>{children}</>;
};
