import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { ScreenSize, useScreenSize } from '../use_screen_size';

type PropsGridContainer = {
  container: true;
  columns?: number;
  gap?: number | string;
  style?: CSSProperties;
  children?: ReactNode;
};

type PropsGridItem = {
  container?: false;
  item: boolean;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  style?: CSSProperties;
  children?: ReactNode;
};

type Props = PropsGridContainer | PropsGridItem;

const GridContainer: FunctionComponent<PropsGridContainer> = ({
  children,
  columns,
  gap,
  style,
}) => {
  const cols = typeof columns !== 'undefined' ? columns : 12;

  return (
    <div
      style={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        gridGap: gap,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

const getSize = (sizes: Array<undefined | number>, defaultSize: number) => {
  for (const size of sizes) {
    if (typeof size !== 'undefined') {
      return size;
    }
  }

  return defaultSize;
};

const GridItem: FunctionComponent<PropsGridItem> = props => {
  const isXs = useScreenSize(ScreenSize.ExtraSmall);
  const isSm = useScreenSize(ScreenSize.Small);
  const isMd = useScreenSize(ScreenSize.Medium);
  const isLg = useScreenSize(ScreenSize.Large);
  const isXl = useScreenSize(ScreenSize.ExtraLarge);

  let size;
  if (isXs) {
    size = getSize([props.xs], 1);
  } else if (isSm) {
    size = getSize([props.sm, props.xs], 1);
  } else if (isMd) {
    size = getSize([props.md, props.sm, props.xs], 1);
  } else if (isLg) {
    size = getSize([props.lg, props.md, props.sm, props.xs], 1);
  } else if (isXl) {
    size = getSize([props.xl, props.lg, props.md, props.sm, props.xs], 1);
  }

  return (
    <div style={{ gridColumn: `span ${size}`, ...props.style }}>
      {props.children}
    </div>
  );
};

export const Grid: FunctionComponent<Props> = props => {
  if (props.container) {
    return <GridContainer {...props} />;
  }

  return <GridItem {...props} />;
};
