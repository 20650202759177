import React, { FunctionComponent } from 'react';
import {
  DiscordPresence,
  DiscordPresenceClassesCode,
} from 'react-discord-presence';
import {
  Grid,
  Link,
  List,
  ListItem,
  ScreenSize,
  Typography,
  useScreenSize,
} from '../../component/display';
import { GravatarProfile } from '../../component/gravatar';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutContentText,
  PageLayoutFooter,
  PageLayoutHeader,
  PageLayoutHelmet,
} from '../../component/page_layout';
import { PageAboutDonateBuyMeACoffee } from './PageAboutDonateBuyMeACoffee';
import { PageAboutDonatePoatreon } from './PageAboutDonatePatreon';
import pgp_public_key from './nate-wilkins_pubkey.asc.txt';
import resume_pdf from './nate-wilkins_resume.pdf';

export const PageAbout: FunctionComponent = () => {
  const isXs = useScreenSize(ScreenSize.ExtraSmall);

  const description =
    "I'm a software engineering professional who " +
    "is passionate about technology and it's impact " +
    'on the end user. Experienced with comprehensive ' +
    'skills in the design, development, test, and ' +
    'maintenance of software systems.';

  return (
    <PageLayout>
      <PageLayoutHelmet>
        <title>About | CodeNull</title>
        <meta name="keywords" content="CodeNull,about,software,engineer,blog" />
        <meta name="description" content={description} />
      </PageLayoutHelmet>
      <PageLayoutHeader />

      <PageLayoutContent>
        <PageLayoutContentText>
          <Grid container gap={16}>
            <Grid item xs={12}>
              <Grid container gap={8}>
                <Grid item xs={12} sm={7} lg={9}>
                  <Grid container gap={16}>
                    <Grid item xs={12}>
                      <Typography variant="titleXl">About Me</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container gap={16}>
                        <Grid item xs={12}>
                          <Typography variant="body">
                            My name is{' '}
                            <Typography inline variant="code">
                              nate-wilkins
                            </Typography>
                            . Also known as{' '}
                            <Typography inline variant="code">
                              dnXviral
                            </Typography>{' '}
                            in the gaming community.
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="body">{description}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="body">
                            A hard worker who loves to learn and provide
                            technical leadership through guidance and real-world
                            examples.
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <List>
                            <ListItem>
                              <Typography variant="body">
                                <b>Specialties:</b> TypeScript, Rust, and C#
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography variant="body">
                                <b>Industries:</b> Healthcare and
                                Medical/Imaging IT
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography variant="body">
                                <b>Interests:</b> UI/UX, Operating Systems,
                                Algorithims, and Security
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography variant="body">
                                <b>Hobbies:</b> Skiing, Snowboarding, and Gaming
                              </Typography>
                            </ListItem>
                          </List>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="body">
                            If you need to get ahold of me you can find me on
                            twitter as{' '}
                            <Link
                              isExternal
                              href="https://twitter.com/_natewilkins"
                            >
                              @_natewilkins
                            </Link>{' '}
                            or shoot me an email here on code-null.
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="body">
                            My PGP public key can be found{' '}
                            <Link isExternal href={pgp_public_key}>
                              here
                            </Link>
                            .
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={5}
                  lg={3}
                  style={{
                    display: 'flex',
                    justifyContent: !isXs ? 'flex-end' : 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <GravatarProfile
                      email="nate-wilkins@code-null.com"
                      style={{
                        height: '15.625em',
                        width: '15.625em',
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="titleLg">Resume</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container gap={16}>
                <Grid item xs={12}>
                  <Typography variant="body">
                    My résumé is on{' '}
                    <Link
                      isExternal
                      href="https://github.com/nate-wilkins/about"
                    >
                      GitHub
                    </Link>
                    .
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <object
                    data={`${window.location.origin}${resume_pdf}`}
                    type="application/pdf"
                    width="100%"
                    height="800px"
                  >
                    <Grid container gap={8}>
                      <Grid item xs={12}>
                        <Typography variant="body">
                          <b>This browser does not support PDFs.</b>
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="body">
                          Please{' '}
                          <Link isExternal href={resume_pdf}>
                            download the PDF
                          </Link>{' '}
                          to view it.
                        </Typography>
                      </Grid>
                    </Grid>
                  </object>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="titleLg">Gaming</Typography>
            </Grid>

            <Grid item xs={12}>
              <DiscordPresence
                classes={DiscordPresenceClassesCode}
                args={{ developerId: '194976024457510912' }}
                theme={{
                  primary: 'rgba(38, 114, 195, 1)',
                  accent: 'rgba(0, 26, 48, 1)',
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="titleLg">Donate</Typography>
            </Grid>

            <Grid item xs={12} style={{ display: 'flex' }}>
              <PageAboutDonateBuyMeACoffee
                style={{ height: '2.625em', marginRight: '0.5em' }}
              />
              <PageAboutDonatePoatreon style={{ height: '2.625em' }} />
            </Grid>
          </Grid>
        </PageLayoutContentText>
      </PageLayoutContent>

      <PageLayoutFooter />
    </PageLayout>
  );
};
