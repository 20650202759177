import { format } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { BsRssFill } from 'react-icons/bs';
import { LandingAnimationFade } from '../../router';
import { Grid, Link, Typography, TypographySpacer } from '../display';
import { PageLayoutCenter } from './PageLayoutCenter';
import feedRss from '../../page/blog/posts/feed.xml';
import version from '../../version.json';
import classes from './PageLayoutFooter.module.css';

export const PageLayoutFooter: FunctionComponent = () => {
  return (
    <LandingAnimationFade duration={0.5} delay={0.5}>
      <footer className={classes.footer}>
        <PageLayoutCenter
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'flex-end',
            flexDirection: 'column',
          }}
        >
          <LandingAnimationFade duration={0.5} delay={0.25}>
            <Grid container>
              <Grid
                item
                xs={6}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                <Typography
                  variant="captionSm"
                  noWrap
                  style={{ color: 'inherit' }}
                >
                  Copyright &copy; {format(new Date(), 'yyyy')} CodeNull
                </Typography>
                <TypographySpacer
                  style={{
                    color: 'inherit',
                    marginRight: '0.5em',
                    borderLeftColor: 'var(--theme-color-text-contrast)',
                  }}
                />
                <Typography
                  variant="captionSm"
                  noWrap
                  style={{ color: 'inherit' }}
                >
                  v:{version.version.short}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Link
                  isExternal
                  href={feedRss}
                  variant="contrast"
                  style={{
                    textDecorationThickness: 'inherit',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="captionSm"
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      color: 'inherit',
                    }}
                  >
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <BsRssFill style={{ marginRight: '0.3em' }} />
                      RSS
                    </div>
                  </Typography>
                </Link>
                <TypographySpacer
                  style={{
                    color: 'inherit',
                    marginRight: '0.5em',
                    borderLeftColor: 'var(--theme-color-text-contrast)',
                  }}
                />
                <Link
                  href="/sitemap"
                  variant="contrast"
                  style={{
                    textDecorationThickness: 'inherit',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="captionSm" style={{ color: 'inherit' }}>
                    Sitemap
                  </Typography>
                </Link>
                <TypographySpacer
                  style={{
                    color: 'inherit',
                    marginRight: '0.5em',
                    borderLeftColor: 'var(--theme-color-text-contrast)',
                  }}
                />
                <Link
                  href="/about"
                  variant="contrast"
                  style={{
                    textDecorationThickness: 'inherit',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="captionSm" style={{ color: 'inherit' }}>
                    About
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </LandingAnimationFade>
        </PageLayoutCenter>
      </footer>
    </LandingAnimationFade>
  );
};
