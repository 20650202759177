import { Assets } from 'src/page/blog/posts/assets';
import frontMatter from 'front-matter';
import { Posts } from '../../../src/page/blog/posts';
import { Response } from '../types';
import { Post, PostEntry } from './types';

export * from './types';

export type QueryGetPostRequest = {
  name: string;
};

export type QueryGetPostResponse = {
  post: Post;
};

/*
 * Get a particular blog post.
 */
export const getBlogPost = async ({
  name,
}: QueryGetPostRequest): Promise<Response<QueryGetPostResponse>> => {
  // Does the post exist?
  const post = Posts[name];
  if (!post) {
    return {
      status: 404,
      data: null,
    };
  }

  // Get post content.
  const response = await fetch(Assets[post.asset]);
  if (response.status !== 200) {
    return {
      status: response.status,
      data: null,
    };
  }
  const responseContent = await response.text();

  return {
    status: 200,
    data: {
      post: {
        name,
        frontmatter: {
          ...post.frontmatter,
          keywords: post.frontmatter.keywords.map(k => k.toLowerCase()),
        },
        content: frontMatter(responseContent).body,
      },
    },
  };
};

export type QueryGetPostEntriesRequest = Record<string, never>;

export type QueryGetPostEntriesResponse = {
  postEntries: PostEntry[];
};

/*
 * Get blog post entries.
 */
export const getBlogPostEntries = async (
  _parameters: QueryGetPostEntriesRequest,
): Promise<Response<QueryGetPostEntriesResponse>> => {
  return {
    status: 200,
    data: {
      postEntries: Object.values(Posts).sort((a, b) => {
        if (a.frontmatter.date < b.frontmatter.date) {
          return 1;
        } else if (a.frontmatter.date > b.frontmatter.date) {
          return -1;
        } else {
          return 0;
        }
      }),
    },
  };
};
