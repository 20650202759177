import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import classes from './AnimationFade.module.css';

/*
 * Animation effect to fade content.
 *
 * @param time - How fast the animation should be in seconds.
 */
export const AnimationFade: FunctionComponent<{
  duration: number;
  delay?: number;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}> = ({ duration, delay, className, style, children }) => {
  return (
    <div
      className={`${classes.fade} ${className}`}
      style={{
        animationDuration: `${duration}s`,
        ...(delay
          ? {
              animationDelay: `${delay}s`,
            }
          : {}),
        ...style,
      }}
    >
      {children}
    </div>
  );
};
