import { default as React, FunctionComponent } from 'react';
import { ApplicationContainer } from './ApplicationContainer';
import { ApplicationDetails } from './ApplicationDetails';
import { Router } from './router';

/*
 * Application setup.
 *
 * Sets up the following:
 * - Root providers
 * - Router
 */
export const Application: FunctionComponent = () => {
  return (
    <ApplicationContainer>
      <ApplicationDetails />
      <Router />
    </ApplicationContainer>
  );
};
