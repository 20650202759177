import { useMediaQuery } from './use_media_query';

export enum ScreenSize {
  ExtraSmall = 'ExtraSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  ExtraLarge = 'ExtraLarge',
}

const ScreenSizes: { [Property in ScreenSize]: string } = {
  [ScreenSize.ExtraSmall]: '(max-width: 600px)',
  [ScreenSize.Small]: '(max-width: 960px)',
  [ScreenSize.Medium]: '(max-width: 1280px)',
  [ScreenSize.Large]: '(max-width: 1920px)',
  [ScreenSize.ExtraLarge]: '(min-width: 1920px)',
};

export const useScreenSize = (query: ScreenSize): boolean => {
  return useMediaQuery(ScreenSizes[query]);
};

export const useScreenSizeValue = (): ScreenSize => {
  const isXs = useScreenSize(ScreenSize.ExtraSmall);
  const isSm = useScreenSize(ScreenSize.Small);
  const isMd = useScreenSize(ScreenSize.Medium);
  const isLg = useScreenSize(ScreenSize.Large);
  const isXl = useScreenSize(ScreenSize.ExtraLarge);

  if (isXs) {
    return ScreenSize.ExtraSmall;
  } else if (isSm) {
    return ScreenSize.Small;
  } else if (isMd) {
    return ScreenSize.Medium;
  } else if (isLg) {
    return ScreenSize.Large;
  } else if (isXl) {
    return ScreenSize.ExtraLarge;
  }

  return ScreenSize.ExtraLarge;
};
