import { Navigate } from '@null-studios/browser-router';
import React, { CSSProperties, FunctionComponent } from 'react';
import classes from './Link.module.css';

type Props = typeof Navigate.defaultProps & {
  variant?: 'normal' | 'contrast';
  style?: CSSProperties;
  className?: string;
};

export const Link: FunctionComponent<Props> = ({
  variant,
  children,
  style,
  className,
  ...propsNavigate
}) => {
  let classType;
  if (!variant || variant === 'normal') {
    classType = classes.variantNormal;
  } else {
    classType = classes.variantContrast;
  }

  return (
    <span className={`${classType} ${className}`} style={style}>
      <Navigate
        {...propsNavigate}
        style={{
          height: 'inherit',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {children}
      </Navigate>
    </span>
  );
};
