// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".List_listUnordered__F517n {\n  margin-left: 0.75em;\n  list-style-position: inside;\n  list-style-type: none;\n}\n.List_listOrdered__ipq\\+t {\n  margin-left: 1.75em;\n}\n", "",{"version":3,"sources":["webpack://./src/component/display/list/List.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,qBAAqB;AACvB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".listUnordered {\n  margin-left: 0.75em;\n  list-style-position: inside;\n  list-style-type: none;\n}\n.listOrdered {\n  margin-left: 1.75em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listUnordered": "List_listUnordered__F517n",
	"listOrdered": "List_listOrdered__ipq+t"
};
export default ___CSS_LOADER_EXPORT___;
