import React, { FunctionComponent, ReactNode } from 'react';
import { LandingAnimationFade } from '../../router';
import { PageLayoutCenter } from './PageLayoutCenter';

export const PageLayoutContent: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <PageLayoutCenter style={{ flex: 1, marginBottom: '3em' }}>
      <LandingAnimationFade duration={0.5} delay={0.3}>
        {children}
      </LandingAnimationFade>
    </PageLayoutCenter>
  );
};
