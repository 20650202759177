import React, { FunctionComponent, ReactNode, Suspense } from 'react';
import classes from './PageLayout.module.css';

type Props = {
  children: ReactNode;
};

export const PageLayout: FunctionComponent<Props> = ({ children }) => {
  return (
    <Suspense fallback={null}>
      <div className={`${classes.content}`}>{children}</div>
    </Suspense>
  );
};
